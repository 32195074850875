import React from "react";
import { graphql } from "gatsby";
import Layout from "components/layout";
import TicketShop from "components/ticket-shop";
import useTicketshopTracking from "hooks/use-ticketshop-tracking";
import styles from "./styles.module.css";

export const query = graphql`
  query EventPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        ticketshop
      }
    }
  }
`;

const CityTemplate = ({ data }) => {
  useTicketshopTracking();
  const { title, ticketshop } = data.markdownRemark.frontmatter;
  return (
    <Layout title={title}>
      <div className={styles.cityContainer}>
        <header>
          <h1>{title}</h1>
        </header>
        <div
          dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
        ></div>
        <TicketShop scriptString={ticketshop}></TicketShop>
      </div>
    </Layout>
  );
};

export default CityTemplate;
